import NavigationBar from "../components/NavigationBar/NavigationBar";
import Menu from "../components/menu/menu";
import Footer from "../components/Footer/Footer";

const MyTourneys = () => {
  return (
    <>
      <NavigationBar />
      <Menu />
      <div className="main">Page Currently Under Construction</div>
      <Footer />
    </>
  );
};

export default MyTourneys;
